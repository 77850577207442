<template>
	<div class="container">
		<bread-crums :nesting="['Dental Care', 'General Dentistry']"></bread-crums>
		<intro button-text="Contact Us" button-link="/contact" title="General Dentistry" :content="paragraphs"/>
		<div class="pgcontent">
			<div v-for="card in cards" :key="card.title" class="card">
				<h2>{{card.title}}</h2>
				<p>{{card.content}}</p>
			</div>
			<div class="table">
				<price-table :procedure="Procedures['categories'][0]"></price-table>
			</div>
		</div>      
	</div>
</template>

<script>
import Intro from '../components/Intro.vue'
import PriceTable from '../components/PriceTable.vue'
import  Procedures  from "../assets/json/procedures.json";
import BreadCrums from '../components/BreadCrums.vue';
export default {
	name: 'GeneralDentisty',
	components: {
		Intro,
		PriceTable,
		BreadCrums
	},
	data(){
		return{
			Procedures,
			paragraphs: [
					"We would normally recommend that you attend on a 6-monthly basis, however in some situations we may suggest that we would adjust this to a more frequent basis or a longer time period of yearly. During this appointment we will carry out dental health screening and assessment to look at your oral health that includes several components such as the soft tissues, gums, existing restorations or previous work and oral cancer screening (to name a few). We may need to take X-rays to assess your dentition and suggest that you visit the hygienist. We feel that prevention is better than cure and would recommend that you attend on a regular basis, as prescribed by your dentist."
			],
			cards:[
			]
		}
	}
}
</script>

<style scoped>
	.pgcontent {
		background-color: var(--primary-variant);
		padding: 1.5rem;
	}
	h2{
		width: 95%;
		color: var(--primary)
	}
	@media screen and (min-width: 375px){
		.pgcontent{
			padding: 1.5rem 2.5rem;
		}
	}
	@media screen and (min-width: 768px){
		.pgcontent{
			padding: 1.5rem6rem;
		}	
	}
	@media screen and (min-width: 1024px) {
		
		.container{
			padding-top: 1rem;
		}
		.pgcontent{
			display: flex;
			flex-wrap: wrap;
			padding: 2rem var(--padding1);
			justify-content: center;
		}
		.card{
			max-width: 50%;			
		}
		p{
			width: 70%;
		}
		.table{
			width: 100%;
		}
		
	}
	@media screen and (min-width: 1440px) {		
		.pgcontent{
			padding: 2rem var(--padding2);
		}    
	}
	@media screen and (min-width: 1750px){
		.pgcontent{
			padding: 2rem 4rem;
		}    
	}
</style>